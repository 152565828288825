import {NotificationsElement} from '@talkdust/notifications-component';
import {ControlsElement} from '@talkdust/controls-component';

const firstTime = (typeof localStorage.getItem('firstLogin') === 'undefined' || localStorage.getItem('firstLogin') === null);

const utilityObj = {};

let activeFetchCount = 0;
utilityObj.linkImages = {};
utilityObj.fetchingUpload = {};
utilityObj.uploadedImages = {};
utilityObj.fetching = {};

const clientUtilities = {
  waitingElesLookup: {},
  registerOnFetchedHandle: function(key, handleFunc) {
    if (typeof this.waitingElesLookup[key] === 'undefined') {
      this.waitingElesLookup[key] = [];
    }
    this.waitingElesLookup[key].push(handleFunc);
  },
};
utilityObj.clientUtilities = clientUtilities;

// TODO switch this over to a user login thing via fetch
const lastUpdateSeen = localStorage.getItem('lastUpdate') || 'NA';

const uniEmoji = document.createElement('div');

const dialogContainer = document.createElement('div');
const dialog = document.createElement('div');
dialog.setAttribute('id', 'talkdust_dialog');

const shader = document.createElement('div');
shader.setAttribute('id', 'shader-2');
shader.onclick = hideDialog;
const shaderNoClick = document.createElement('div');
shaderNoClick.setAttribute('id', 'shader-3');

const invisaShader = document.createElement('div');
invisaShader.setAttribute('id', 'invisaShader');
const okBtn = document.createElement('div');
okBtn.classList.add('ok');
okBtn.innerHTML='OK';
okBtn.setAttribute('id', 'okBtn');
const closeBtn = document.createElement('div');
closeBtn.classList.add('close');
closeBtn.innerHTML='<i class="far fa-window-close"></i>';

// TODO REMOVE THIS
const promptBar = document.createElement('div');
promptBar.setAttribute('id', 'taldkust-prompt-bar');
// TODO END REMOVE

const overlayContainer = document.createElement('div');
const overlay = document.createElement('div');
overlay.setAttribute('id', 'talkdust_overlay');
// const shader = document.createElement('div');
// shader.setAttribute('id', 'shader-2');
// shader.onclick = hideDialog;
const invisaShader2 = document.createElement('div');
invisaShader2.setAttribute('id', 'invisaShader2');
const okBtn2 = document.createElement('div');
okBtn2.classList.add('ok');
okBtn2.innerHTML='OK';
const closeBtn2 = document.createElement('div');
closeBtn2.classList.add('close');
closeBtn2.innerHTML='<i class="far fa-window-close"></i>';

// closeBtn.innerHTML='<i class=\'fas fa-close\' aria-hidden=\'true\'></i>';

okBtn.onclick=hideDialog;
closeBtn.onclick=hideDialog;
invisaShader.onclick = hideDialog;

okBtn2.onclick=hideOverlay;
closeBtn2.onclick=hideOverlay;
invisaShader2.onclick = hideOverlay;

dialogContainer.appendChild(shader);
dialogContainer.appendChild(invisaShader);
dialogContainer.appendChild(dialog);
dialogContainer.appendChild(shaderNoClick);

overlayContainer.appendChild(overlay);
overlayContainer.appendChild(invisaShader2);

/**
 * utility function for an invisible pane to catch click events and handle accordingly
 * @param {function} clickedCallback - function to be called upon click event
 */
function enableInvisashader(clickedCallback = () => undefined) {
  invisaShader2.classList.add('show');
  invisaShader2.onclick = function() {
    hideOverlay();
    clickedCallback();
    invisaShader2.onclick = hideOverlay;
  };
}
utilityObj.enableInvisashader = enableInvisashader;

/**
 * Show Chat Message OVerlay
 * @param {Object} options
 */
function showErrorDialog(options) {
  hideDialog();
  dialog.className='error-dialog show';

  const heading = document.createElement('h2');
  heading.innerHTML = options.heading || 'Oopsies...';
  const message = document.createElement('p');
  message.innerHTML = options.message || '';

  dialog.appendChild(heading);
  dialog.appendChild(message);
  dialog.appendChild(okBtn);
  dialog.appendChild(closeBtn);

  dialog.style.top = `${window.scrollY + dialog.offsetTop }px`;

  if (options.invisaShader === true) {
    invisaShader.classList.add('show');
  } else if (options.noShader === true) {
    // no op
    shaderNoClick.classList.add('show');
  } else {
    shader.classList.add('show');
  }
}
utilityObj.showErrorDialog = showErrorDialog;

/**
 * Show message
 * @param {Object} options
 */
function showMessageDialog(options) {
  hideDialog();
  dialog.className='message-dialog show';

  const heading = document.createElement('h2');
  heading.innerHTML = options.heading || 'TD!';
  const message = document.createElement('p');
  if (options.message instanceof HTMLElement) {
    message.appendChild(options.message);
  } else {
    message.innerHTML = options.message || '';
  }

  dialog.appendChild(heading);
  dialog.appendChild(message);
  dialog.appendChild(okBtn);
  dialog.appendChild(closeBtn);

  dialog.style.top = `${window.scrollY + dialog.offsetTop }px`;

  if (options.invisaShader === true) {
    invisaShader.classList.add('show');
  } else if (options.noShader === true) {
    // no op
    shaderNoClick.classList.add('show');
  } else {
    shader.classList.add('show');
  }
}
utilityObj.showMessageDialog = showMessageDialog;

/**
 * Show Custom Dialog
 * @param {Object} options
 */
function showCustomDialog(options) {
  hideDialog();
  dialog.className='show';

  const dialogClass = options.className || 'custom-dialog';
  dialog.classList.add(dialogClass);

  const heading = document.createElement('h2');
  heading.innerHTML = options.heading || 'TD!';

  const content = document.createElement('p');
  if (typeof options.contentDOM === 'undefined') {

  } else if (typeof options.contentDOM !== 'object' || ! (options.contentDOM instanceof Node)) {
    throw new ReferenceError('Unsupported contentDOM passed to showCustomDialog');
  }
  content.appendChild(options.contentDOM);

  dialog.appendChild(heading);
  dialog.appendChild(content);
  // dialog.appendChild(okBtn);
  dialog.appendChild(closeBtn);

  if (options.invisaShader === true) {
    invisaShader.classList.add('show');
  } else if (options.noShader === true) {
    // no op
    shaderNoClick.classList.add('show');
  } else {
    shader.classList.add('show');
  }
}
utilityObj.showCustomDialog = showCustomDialog;

/**
 * Show Settings
 * @param {function} exitCallback
 */
function showSettingsDialog(exitCallback) {
  const options = {
    heading: 'Account Actions',
    message: `${TEXT_ENUMS.accountHello } <i>${shellObj.getCookie('alias') || 'Anonymous'}</i>`,
    choices: [{
      wording: 'VIEW PROFILE',
      handle: function() {
        window.location.href='/profile.html';
      },
    }, {
      wording: 'LOGOUT',
      handle: logOutFunction,
    }],
  };

  hideDialog();
  dialog.className='prompt-dialog settings-dialog show';

  const heading0 = document.createElement('h1');
  heading0.innerHTML = 'Settings';
  const heading2 = document.createElement('h2');
  heading2.innerHTML = 'Account Actions';
  const message = document.createElement('p');
  message.innerHTML = options.message || '';

  dialog.appendChild(heading0);

  // when view settings are present
  if (typeof viewSettingsOptions === 'object') {
    const heading1 = document.createElement('h2');
    heading1.innerHTML = viewSettingsOptions.heading || 'View Settings';
    dialog.appendChild(heading1);

    if (viewSettingsOptions.inputs instanceof Array) {
      const div = document.createElement('div');
      div.classList.add('settings');
      viewSettingsOptions.inputs.forEach((inputObj, index) => {
        switch (inputObj.type) {
          case 'checkbox':
            const label1 = document.createElement('p');
            // label1.setAttribute('for', `input${index}`)
            label1.innerHTML = inputObj.label;
            label1.style['text-align'] ='right';
            label1.style.width='100px';
            const label = document.createElement('label');
            label.classList.add('switch');
            const input = document.createElement('input');
            input.setAttribute('type', 'checkbox');
            input.setAttribute('name', `input${index}`);
            input.setAttribute('id', `input${index}`);
            input.checked = inputObj.status === 'checked';
            input.onchange = inputObj.onChange;
            const span = document.createElement('span');
            span.classList.add('slider');

            label.appendChild(input);
            label.appendChild(span);
            div.appendChild(label1);
            div.appendChild(label);
            div.appendChild(document.createElement('br'));
            break;
          default:
            break;
        }
      });
      dialog.appendChild(div);
    }
  }

  dialog.appendChild(heading2);
  dialog.appendChild(message);
  dialog.appendChild(closeBtn);

  const choices = options.choices || [];
  choices.forEach(function(choice, index) {
    const btnOption = document.createElement('div');
    btnOption.id = `prompt-choice-${index}`;
    btnOption.innerHTML=choice.wording;
    btnOption.classList.add('prompt-button');
    if (choice.wording === 'LOGOUT') {
      btnOption.classList.add('g_id_signout');
    }
    btnOption.onclick=function() {
      choice.handle(); hideDialog();
      if (typeof exitCallback !== 'undefined') {
        exitCallback();
      }
    };
    dialog.appendChild(btnOption);
  });
  if (options.invisaShader === true) {
    invisaShader.classList.add('show');
  } else if (options.noShader === true) {
    // no op
    shaderNoClick.classList.add('show');
  } else {
    shader.classList.add('show');
  }
}
utilityObj.showSettingsDialog = showSettingsDialog;
// TODO think we simply remove this.

/**
 * TODO find a way to remove the need for this function...
 * @param {Object} options
 */
function populateUnicodeEmojiOverlay(options) {
  const choices = options.choices || [];
  const filterIndexes = [];// 21,63,162,199, 200, 304, 454, 489];
  const nonBrowserImplementedEmojies = {
    'smiling face with tear': 0,
    'disguised face': 0,
    'pinched fingers': 0,
    'anatomical heart': 0,
    lungs: 0,
    ninja: 0,
    bison: 0,
    mammoth: 0,
    dodo: 0,
    'people hugging': 0,
    feather: 0,
    beaver: 0,
    seal: 0,
    beetle: 0,
    cockroach: 0,
    fly: 0,
    worm: 0,
    'potted plant': 0,
    blueberries: 0,
    flatbread: 0,
    'bell pepper': 0,
    olive: 0,
    tamale: 0,
    fondue: 0,
    'bubble tea': 0,
    teapot: 0,
    rock: 0,
    wood: 0,
    hut: 0,
    'pickup truck': 0,
    'roller skate': 0,
    'magic wand': 0,
    piñata: 0,
    'nesting dolls': 0,
    knot: 0,
    'sewing needle': 0,
    'thong sandal': 0,
    accordion: 0,
    'military helmet': 0,
    'carpentry saw': 0,
    boomerang: 0,
    screwdriver: 0,
    hook: 0,
    ladder: 0,
    elevator: 0,
    mirror: 0,
    window: 0,
    plunger: 0,
    'mouse trap': 0,
    bucket: 0,
    toothbrush: 0,
    headstone: 0,
    placard: 0,

  };
  const groups = [
    'Smileys & Emotion',
    'People & Body',
    'Component',
    'Animals & Nature',
    'Food & Drink',
    'Travel & Places',
    'Activities',
    'Objects',
    'Symbols',
    'Flags',
  ];
  const gIndex = {
    // 0:"Smileys & Emotion",
    'waving hand': 'People & Body',
    'light skin tone': 'Component',
    'monkey face': 'Animals & Nature',
    grapes: 'Food & Drink',
    'globe showing Europe-Africa': 'Travel & Places',
    'jack-o-lantern': 'Activities',
    glasses: 'Objects',
    'ATM sign': 'Symbols',
    'chequered flag': 'Flags',
  };

  const search = document.createElement('input');
  search.setAttribute('type', 'text');
  search.setAttribute('id', 'emoji-uni-search');
  search.setAttribute('placeholder', 'type to search for emojies 🔍');
  search.addEventListener('keyup', event => {
    for (let i = 0; i < choices.length; i++) {
      if (typeof nonBrowserImplementedEmojies[choices[i].emojiObj.desc] === 'undefined') {
        if (choices[i].emojiObj.desc.indexOf(event.target.value) === -1) {
          document.getElementById(choices[i].emojiObj.desc).parentNode.style.display = 'none';
        } else {
          document.getElementById(choices[i].emojiObj.desc).parentNode.style.display = 'inline-block';
        }
      }
    }
  });

  uniEmoji.appendChild(search);

  const groupEles = groups.map(grouping => {
    const ele = document.createElement('div');
    ele.setAttribute('id', `g-${grouping}`);
    const header = document.createElement('h3');
    header.innerHTML = grouping;
    ele.appendChild(header);
    return ele;
  });
  let cEle = groupEles[0];
  let cIndex = 0;
  choices.forEach(function(choice, index) {
    if (typeof gIndex[choice.emojiObj.desc] !== 'undefined') {
      cIndex++;
      cEle = groupEles[cIndex];
    }
    if (typeof nonBrowserImplementedEmojies[choice.emojiObj.desc] === 'undefined') {
      const btnOption = document.createElement('div');
      btnOption.id = `emoji-uni-choice-${index}`;
      const wording = `<div id='${choice.emojiObj.desc}' title="${choice.emojiObj.desc}"class="selection">
        ${choice.emojiObj.seq.map(emojieCharCode => `&#x${emojieCharCode};`).join('')}
      </div>`;
      btnOption.innerHTML=wording;
      btnOption.classList.add('emoji-uni-option');

      btnOption.onclick=function() {
        search.value = '';
        for (let i = 0; i < choices.length; i++) {
          if (typeof nonBrowserImplementedEmojies[choices[i].emojiObj.desc] === 'undefined') {
            document.getElementById(choices[i].emojiObj.desc).parentNode.style.display = 'inline-block';
          }
        }
        choice.handle(); hideOverlay(); callback();
      };
      cEle.appendChild(btnOption);
    }
  });
  groupEles.forEach((groupHeaderEle, index) => {
    if (index === 2) {
      groupHeaderEle.style.display='none';
    }
    uniEmoji.appendChild(groupHeaderEle);
  });
}
utilityObj.populateUnicodeEmojiOverlay = populateUnicodeEmojiOverlay;
// TODO another case of chat.js only using this method

/**
 * Shows the Emojie Selection Overlay
 * @param {Object} options
 * @param {function} exitCallback
 */
function showUnicodeEmojiOverlay(options) {
  hideOverlay();
  overlay.className='emoji-uni-dialog show';

  const heading = document.createElement('h2');
  heading.innerHTML = options.heading || 'We need your help!';

  overlay.appendChild(heading);
  overlay.appendChild(closeBtn2);
  overlay.appendChild(uniEmoji);

  invisaShader2.classList.add('show');
}
utilityObj.showUnicodeEmojiOverlay = showUnicodeEmojiOverlay;


/**
 * Shows the Reaction Overlay
 * @param {Object} options
 * @param {function} exitCallback
 */
function showEmojiOverlay(options, exitCallback) {
  hideOverlay();
  overlay.className='emoji-dialog show';

  let color = 'rainbow';
  if (typeof options.color === 'string') {
    color = options.color;
  }

  const heading = document.createElement('h2');
  heading.innerHTML = options.heading || 'We need your help!';

  overlay.appendChild(heading);
  overlay.appendChild(closeBtn2);

  const choices = options.choices || [];
  choices.forEach(function(choice, index) {
    const btnOption = document.createElement('div');
    btnOption.id = `emoji-choice-${index}`;
    btnOption.innerHTML=choice.wording;
    btnOption.classList.add('emoji-option');
    btnOption.classList.add('crowd-color');
    if (color !== 'rainbow') {
      btnOption.onmouseover = function() {
        btnOption.classList.remove('crowd-color');
        btnOption.style.color = color;
      };
      btnOption.onmouseout = function() {
        btnOption.classList.add('crowd-color');
        btnOption.style.color = 'inherit';
      };
    } else {
      btnOption.classList.add('crowd-color');
    }
    btnOption.onclick=function() {
      choice.handle(); hideOverlay(); exitCallback();
    };
    overlay.appendChild(btnOption);
  });
  invisaShader2.classList.add('show');
}
utilityObj.showEmojiOverlay = showEmojiOverlay;

/**
 * method for routing a user to join a room
 * @param {string} topic - topic key to join
 * @param {string} chatServer - still to be implemented and used in routing to correct container
 * @param {string} dataCenter - eventually to be used in additional routing, albeit this may be unecessary
 * @param {string} subRoom - group, backend will be into a non full group
 */
function joinConversation(topic, chatServer, dataCenter) {
  // alert(`${topic},${chatServer},${dataCenter}`);
  if (topic === 'trending-chat') {
    submitFormData(`/chat/${topic}`, {
      action: 'join',
      'chat-server': chatServer,
      'data-center': dataCenter,
    }, 'post');
  } else {
    submitFormData(`/chat/rooms/${topic}`, {
      action: 'join',
      'chat-server': chatServer,
      'data-center': dataCenter,
    }, 'post');
  }
}

/**
 * method for routing a user to spectate a room
 * @param {string} topic - topic key to join
 * @param {string} chatServer - still to be implemented and used in routing to correct container
 * @param {string} dataCenter - eventually to be used in additional routing, albeit this may be unecessary
 * @param {string} subRoom - group, defaults to 0
 */
function spectateConversation(topic, chatServer, dataCenter, subRoom = 0) {
  if (topic === 'trending-chat') {
    submitFormData(`/chat/${topic}`, {
      action: 'spectate',
      'chat-server': chatServer,
      'data-center': dataCenter,
      'sub-room': subRoom,
    }, 'post');
  } else {
    submitFormData(`/chat/rooms/${topic}`, {
      action: 'spectate',
      'chat-server': chatServer,
      'data-center': dataCenter,
      'sub-room': subRoom,
    }, 'post');
  }
}
utilityObj.spectateConversation = spectateConversation;
utilityObj.joinConversation = joinConversation;

/**
 * Full credit to Rakesh Pai https://stackoverflow.com/a/133997/1992129
 * I was going to do this, but wanted to google it to ensure it was possible
 * and his code was essentially copy paste!
 * @param {string} path - endpoint
 * @param {Array} params - key:value pairings
 * @param {string} method - HTTP method (ie POST, GET, PUT, etc)
 */
function submitFormData(path, params, method) {
  // TODO replace this with the fetch API
  // https://stackoverflow.com/a/46642899/1992129
  method = method || 'post'; // Set method to post by default if not specified.

  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

/**
 * Shows the release notes
 * @param {Object} options
 * @param {function} exitCallback
 */
function showReleaseNotes(options, exitCallback) {
  hideDialog();
  dialog.className='prompt-dialog show';

  const message = document.createElement('div');
  message.innerHTML = options.message || '';

  dialog.appendChild(message);
  dialog.appendChild(closeBtn);

  const choices = options.choices || [];
  choices.forEach(function(choice, index) {
    const btnOption = document.createElement('div');
    btnOption.id = `prompt-choice-${index}`;
    btnOption.innerHTML=choice.wording;
    btnOption.classList.add('prompt-button');
    btnOption.onclick=function() {
      choice.handle(); hideDialog(); exitCallback();
    };
    dialog.appendChild(btnOption);
  });
  if (options.invisaShader === true) {
    invisaShader.classList.add('show');
  } else if (options.noShader === true) {
    // no op
    shaderNoClick.classList.add('show');
  } else {
    shader.classList.add('show');
  }
}

/**
 * Shows the Prompt dialog
 * @param {Object} options
 * @param {function} exitCallback
 */
function showPromptDialog(options, exitCallback=() => undefined) {
  hideDialog();
  dialog.className='prompt-dialog show';

  const heading = document.createElement('h2');
  heading.innerHTML = options.heading || 'We need your help!';
  const message = document.createElement('p');
  message.innerHTML = options.message || '';

  dialog.appendChild(heading);
  dialog.appendChild(message);
  dialog.appendChild(closeBtn);

  const choices = options.choices || [];
  choices.forEach(function(choice, index) {
    const btnOption = document.createElement('div');
    btnOption.id = `prompt-choice-${index}`;
    btnOption.innerHTML=choice.wording;
    btnOption.classList.add('prompt-button');
    btnOption.onclick=function() {
      choice.handle(); hideDialog(); exitCallback(); // if the buttons are being non-responsive change to `hideDialog(); exitCallback(); choice.handle();`
    };
    dialog.appendChild(btnOption);
  });
  if (options.invisaShader === true) {
    invisaShader.classList.add('show');
  } else if (options.noShader === true) {
    // no op
    shaderNoClick.classList.add('show');
  } else {
    shader.classList.add('show');
  }
}
utilityObj.showPromptDialog = showPromptDialog;


const promptQueue = [];
const promptMemory = {};
let promptIsDisplayed = false;

// TODO REMOVE THIS
/**
 * Shows Prompt Bar
 * @param {Object} options
 */
function showPromptBar(options) {
  if (promptIsDisplayed && (typeof options.force === 'undefined' || options.force !== true)) {
    // add to queue and return;
    if (typeof promptMemory[options.message + options.choices.map(choiceObj => choiceObj.wording).join(':')] === 'undefined') {
      promptQueue.push(options);
      promptMemory[options.message + options.choices.map(choiceObj => choiceObj.wording).join(':')] = true;
    }
    return;
  }

  if (typeof promptMemory[options.message + options.choices.map(choiceObj => choiceObj.wording).join(':')] === 'undefined') {
    promptMemory[options.message + options.choices.map(choiceObj => choiceObj.wording).join(':')] = true;
  } else {
    return;
  }

  promptIsDisplayed = true;

  const dismissCallback = function() {
    promptBar.innerHTML = '';
    promptBar.classList.remove('show');
    if (promptQueue.length > 0) {
      resetPrompt(promptQueue.shift());
    } else {
      promptIsDisplayed = false;
    }
  };

  const resetPrompt = function(options) {
    promptBar.innerHTML = '';
    promptBar.className='prompt-bar show';

    const message = document.createElement('p');
    message.innerHTML = options.message || 'Prompt Bar Default';

    promptBar.appendChild(message);

    const choices = options.choices || [];
    choices.forEach(function(choice, index) {
      const btnOption = document.createElement('div');
      btnOption.id = `prompt-bar-choice-${index}`;
      btnOption.innerHTML=choice.wording;
      btnOption.classList.add('prompt-bar-choice');
      btnOption.onclick=function() {
        if (typeof choice.handle === 'function') {
          choice.handle();
        }
        dismissCallback();
      };
      promptBar.appendChild(btnOption);
    });

    const dismissOption = document.createElement('div');
    dismissOption.id = `prompt-bar-dismiss`;
    dismissOption.innerHTML='Dismiss';
    dismissOption.classList.add('prompt-bar-choice');
    dismissOption.onclick=dismissCallback;
    promptBar.appendChild(dismissOption);
  };

  resetPrompt(options);
}
// TODO END REMOVE

/**
 * hides the overlay and the opaque pane
 */
function hideDialog() {
  // TODO special snowflake code because FB and Google can be difficult to init
  // eval making a one-off dialog for authentication
  if (dialog.querySelector('auth-component') !== null) {
    document.querySelector('#auth-hidden-comp').appendChild(dialog.querySelector('auth-component'));
  }; // END TODO

  dialog.innerHTML = '';
  dialog.style.top = '10vh';
  dialog.classList.remove('show');
  shader.classList.remove('show');
  shaderNoClick.classList.remove('show');
  invisaShader.classList.remove('show');
}
utilityObj.hideDialog = hideDialog;

/**
 * hides the overlay and invisble pane
 */
function hideOverlay() {
  overlay.innerHTML = '';
  overlay.classList.remove('show');
  invisaShader2.classList.remove('show');
  invisaShader2.onclick = hideOverlay;
}

// TODO replace with waiting SVG
/**
 * @return {HTMLElement} waiting dialog
 */
function getLoadingElement() {
  const ele = document.createElement('div');
  ele.innerHTML = `<div id="loading" style="height: 100%;width: 100%;position: absolute;margin-left: inherit;">
    <div>
      <svg style="overflow: visible;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.07 133.45">
        <defs>
          <style>.cls-12{fill:none;stroke:#e0dcda;stroke-miterlimit:10;stroke-width:4px;}</style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path id="wire" class="cls-12" d="M15.92,118.16c.78,0,1.57-.08,2.35-.18,1.84-.26,4.19-.16,4.85-2.13.55-1.61-1.62-2.44-2.64-3.53-15.94-17-21.81-36.55-16.66-58A64.68,
          64.68,0,1,1,45,127.49a36.13,36.13,0,0,1-9.13-3.81c-1.55-.91-2.5.88-3.64,1.57-5.5,3.36-13.29,3-18.18-.77-1.47-1.13-2.68-2.43-2.33-4.31C12.15,117.85,14.23,118.26,15.92,
          118.16Z"/>
          <circle r="8" fill="#f27a19">
            <animateMotion dur="4.8s" repeatCount="indefinite">
              <mpath xlink:href="#wire"></mpath>
            </animateMotion>
          </circle>
          </g>
        </g>
      </svg>
    </div>
    <div style="position: relative;top: -80%;left: 10%;width: 80%;height: 80%;">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.13 139.38">
        <defs>
          <style>.cls-1{fill:#f27a19;}.cls-2{fill:#fdfbfa;}.cls-3{fill:#fff;}</style>
        </defs>
        <g id="Layer_22" data-name="Layer 2">
          <g id="Layer_1-22" data-name="Layer 1">
            <path class="cls-1" d="M180,127.81c-.68,0-1.37-.07-2.05-.16-1.6-.22-3.65-.13-4.22-1.84-.48-1.41,1.41-2.13,2.3-3.08,13.87-14.76,19-31.81,14.5-50.47a56.3,56.3,0,1,
            0-35.84,63.67,31.41,31.41,0,0,0,7.93-3.31c1.36-.8,2.18.76,3.17,1.36a14.58,14.58,0,0,0,15.83-.67c1.27-1,2.32-2.12,2-3.74C183.29,127.54,181.49,127.9,180,127.81Z"/>
            <path class="cls-2" d="M113.64,83.13c0-12.19.08-24.38-.07-36.57,0-2.93.55-4.19,3.8-4,8.31.4,16.68-.6,24.85,2,19.21,6,31.54,25.28,28.61,44.86s-19.9,34.05-40.17,
            34.17c-4.52,0-9-.15-13.56.07-2.74.13-3.53-.8-3.51-3.48C113.7,107.79,113.64,95.46,113.64,83.13Zm18.83-.08c0,6.3,0,12.6,0,18.89,0,1.76.11,2.76,2.48,2.3,9.63-1.85,
            17-10.17,17.22-19.72.24-10.87-6.82-20.38-16.61-22.52-2.31-.51-3.18.07-3.12,2.57C132.57,70.73,132.47,76.89,132.47,83.05Z"/>
            <path class="cls-1" d="M12.11,101.08c.68,0,1.37-.07,2-.16,1.61-.22,3.66-.13,4.23-1.85C18.85,97.67,17,97,16.08,96,2.21,81.24-2.9,64.19,1.58,45.53A56.3,56.3,0,1,1,
            37.42,109.2a31.63,31.63,0,0,1-7.94-3.31c-1.35-.8-2.17.76-3.16,1.36a14.6,14.6,0,0,1-15.83-.67c-1.27-1-2.33-2.12-2-3.75C8.83,100.81,10.64,101.16,12.11,101.08Z"/>
            <polygon class="cls-3" points="26.37 39.48 26.37 21.71 86.82 21.71 86.82 39.48 66.88 39.48 66.88 102.8 47.17 102.8 47.17 39.48 26.37 39.48"/>
          </g>
        </g>
      </svg>
    </div>
  </div>`;
  return ele;
}
utilityObj.getLoadingElement = getLoadingElement;

/**
 * utility function for queueing up image fetching!
 * @param {string} attachmentName - filename to get meta object/images for
 * @param {number} attempt - current enumerated attempt to fetch
 * @param {number} size -  the width of the image
 * @param {boolean} bustCache - a flag to circumvent cache, if needed
 */
async function handleUploadFetch(attachmentName, attempt=0, size=600, bustCache=false) {
  if (activeFetchCount > 3) {
    setTimeout(() => {handleUploadFetch(attachmentName, 0, size);}, 500);
    return;
  }
  if (typeof utilityObj.fetching[attachmentName] === 'undefined' && typeof utilityObj.uploadedImages[attachmentName] === 'undefined') {
    activeFetchCount++;
    utilityObj.fetching[attachmentName] = true;
    try {
      const myHeaders = new Headers();
      // TODO get the server to properly set headers when a 204 is returned to
      if (bustCache) {
        myHeaders.append('cache-control', 'reload');
      } else {
        myHeaders.append('pragma', 'force-cache');
        myHeaders.append('cache-control', 'force-cache');
      }
      const response = await fetch(`/news/upload?name=${attachmentName}&size=${size}`, {
        // cache: 'force-cache',
        headers: myHeaders,
      });
      activeFetchCount--;
      handleFetchResponse(response, attachmentName, attempt);
    } catch (error) {
      activeFetchCount--;
      delete utilityObj.fetching[attachmentName];
      setTimeout(() => {
        handleUploadFetch(attachmentName, ++attempt, size);
      }, 1000 + 1000 * attempt);
    }
  } else if (utilityObj.fetching[attachmentName] == true) {
    // no op, shouldn't have been called as we're already trying to obtain this image
  } else {
    injectPhoto(attachmentName);
  }

  /**
   * private function to handle response logic
   * @param {object} response - from fetch requests
   * @param {string} attachmentName - name of resource fetching data on
   * @param {number} attempt - how many tries have been made so far
   */
  async function handleFetchResponse(response, attachmentName, attempt) {
    switch (response.status) {
      case 200:
        const jsonObj = await response.json();
        if (typeof jsonObj.imgb64 !== 'undefined' && jsonObj.imgb64 !== null) {
          utilityObj.uploadedImages[attachmentName] = jsonObj;
          injectPhoto(attachmentName);
        } else {
          utilityObj.uploadedImages[attachmentName] = null;
          injectPhoto(attachmentName);
        }
        break;
      case 204:
        if (attempt < 3) {
          delete utilityObj.fetching[attachmentName];
          setTimeout(() => {
            handleUploadFetch(attachmentName, ++attempt, size, true);
          }, 1000 + 1000 * attempt);
        } else {
          utilityObj.uploadedImages[attachmentName] = null;
          injectPhoto(attachmentName);
        }
        break;
      default:
        break;
    }
  }
}
utilityObj.handleUploadFetch = handleUploadFetch;

/**
 * Find all elements that have attachment data, and when there is a valid image,
 * replace the loading icon with the actual image.
 * @param {String} attachmentName - url/filename/etc.
 */
function injectPhoto(attachmentName) {
  if (clientUtilities.waitingElesLookup[attachmentName] instanceof Array) {
    clientUtilities.waitingElesLookup[attachmentName].forEach(handle => {
      handle();
    });
    delete clientUtilities.waitingElesLookup[attachmentName];
  }

  const lightDomElements = Array.from(document.querySelectorAll(`[data-attachment="${attachmentName}"]`));
  // TODO shouldn't be query'ing shadowdom like this, please update it set
  // some JSON and let the component handle these shenanigans
  // ^^^^^ goes away with below:
  // TODO find every instance of image inject and migrate to registering
  // functions for when the resource has been fetched
  // Doing this as part of the condensing checkins task since that is where
  // the code in question also happens to be.
  const shadowDomElements = Array.from(document.querySelector('notification-bar').shadowRoot.querySelectorAll(`[data-attachment="${attachmentName}"]`));
  lightDomElements.concat(shadowDomElements).forEach(fileDomObj => {
    const placeholder = fileDomObj.getElementsByClassName('media-placeholder')[0];
    if (placeholder !== null &&
      typeof placeholder !== 'undefined' &&
      utilityObj.uploadedImages[attachmentName] !== null &&
      typeof utilityObj.uploadedImages[attachmentName] === 'object' &&
      typeof utilityObj.uploadedImages[attachmentName].imgb64 === 'string' &&
        ( utilityObj.uploadedImages[attachmentName].imgb64.startsWith('data') || utilityObj.uploadedImages[attachmentName].imgb64.startsWith('http'))) {
      // if(typeof utilityObj.uploadedImages[attachmentName].imgb64 === 'string' )
      // if (srcAttributeValue === null || (typeof srcAttributeValue === 'string' && ( ! srcAttributeValue.startsWith('data') && ! srcAttributeValue.startsWith('http')))){
      // return mediaContainer;
      // }else{

      // }
      placeholder.parentNode.innerHTML = `<img class="thumbnail" src="${utilityObj.uploadedImages[attachmentName].imgb64}">`;
    } else {
      fileDomObj.classList.remove('media-message');
      const placeholder = fileDomObj.getElementsByClassName('media-placeholder')[0];
      if (placeholder !== null && typeof placeholder !== 'undefined') {
        const badAnchor = fileDomObj.getElementsByClassName('media-placeholder')[0].parentNode;
        fileDomObj.removeChild(badAnchor);
      }
    }
  });
}
utilityObj.injectPhoto = injectPhoto;

/**
 * utility function for queueing up image fetching!
 * @param {string} url - url to get meta object/images for
 * @param {number} size -  the width of the image
 */
async function handleImgFetch(url, size=600) {
  if (activeFetchCount > 3) {
    setTimeout(() => {handleImgFetch(url, size);}, 1500);
    return;
  }

  if ((typeof utilityObj.linkImages[url] === 'undefined' || utilityObj.linkImages[url] === null) && utilityObj.fetchingUpload[url] !== true) {
    activeFetchCount++;
    utilityObj.fetchingUpload[url] = true;
    const formd = new FormData();
    formd.append('url', url);
    formd.append('size', size);
    let jsonObj;
    try { // TODO de-couple and add a GET equivalent, only posting when
      // it needs to be fetched -- rework when refactoring chat.js  to no
      // longer query shadowdom
      jsonObj = await(await fetch('/news/link', {
        method: 'post',
        body: formd,
      })).json();
    } catch (error) {

    }
    activeFetchCount--;

    if (typeof jsonObj !== 'undefined' && typeof jsonObj.imgb64 !== 'undefined' && jsonObj.imgb64 !== null) {
      utilityObj.linkImages[url] = jsonObj;
      injectLink(url);
    } else if (typeof jsonObj !== 'undefined') {
      utilityObj.linkImages[url] = jsonObj;
      injectLink(url);
    } else {
      utilityObj.linkImages[url] = null;
      injectLink(url);
    }
  }
}
utilityObj.handleImgFetch = handleImgFetch;

// TODO this guy can probably be done away with using js built in methods???
/**
 * function to turn named colors to their hex values.
 * @param {string} name - named color
 * @return {string} - hex string equivalent
 */
function nameToHex(name) {
  return {
    aliceblue: '#f0f8ff',
    antiquewhite: '#faebd7',
    aqua: '#00ffff',
    aquamarine: '#7fffd4',
    azure: '#f0ffff',
    beige: '#f5f5dc',
    bisque: '#ffe4c4',
    black: '#000000',
    blanchedalmond: '#ffebcd',
    blue: '#0000ff',
    blueviolet: '#8a2be2',
    brown: '#a52a2a',
    burlywood: '#deb887',
    cadetblue: '#5f9ea0',
    chartreuse: '#7fff00',
    chocolate: '#d2691e',
    coral: '#ff7f50',
    cornflowerblue: '#6495ed',
    cornsilk: '#fff8dc',
    crimson: '#dc143c',
    cyan: '#00ffff',
    darkblue: '#00008b',
    darkcyan: '#008b8b',
    darkgoldenrod: '#b8860b',
    darkgray: '#a9a9a9',
    darkgreen: '#006400',
    darkkhaki: '#bdb76b',
    darkmagenta: '#8b008b',
    darkolivegreen: '#556b2f',
    darkorange: '#ff8c00',
    darkorchid: '#9932cc',
    darkred: '#8b0000',
    darksalmon: '#e9967a',
    darkseagreen: '#8fbc8f',
    darkslateblue: '#483d8b',
    darkslategray: '#2f4f4f',
    darkturquoise: '#00ced1',
    darkviolet: '#9400d3',
    deeppink: '#ff1493',
    deepskyblue: '#00bfff',
    dimgray: '#696969',
    dodgerblue: '#1e90ff',
    firebrick: '#b22222',
    floralwhite: '#fffaf0',
    forestgreen: '#228b22',
    fuchsia: '#ff00ff',
    gainsboro: '#dcdcdc',
    ghostwhite: '#f8f8ff',
    gold: '#ffd700',
    goldenrod: '#daa520',
    gray: '#808080',
    green: '#008000',
    greenyellow: '#adff2f',
    honeydew: '#f0fff0',
    hotpink: '#ff69b4',
    'indianred ': '#cd5c5c',
    indigo: '#4b0082',
    ivory: '#fffff0',
    khaki: '#f0e68c',
    lavender: '#e6e6fa',
    lavenderblush: '#fff0f5',
    lawngreen: '#7cfc00',
    lemonchiffon: '#fffacd',
    lightblue: '#add8e6',
    lightcoral: '#f08080',
    lightcyan: '#e0ffff',
    lightgoldenrodyellow: '#fafad2',
    lightgrey: '#d3d3d3',
    lightgreen: '#90ee90',
    lightpink: '#ffb6c1',
    lightsalmon: '#ffa07a',
    lightseagreen: '#20b2aa',
    lightskyblue: '#87cefa',
    lightslategray: '#778899',
    lightsteelblue: '#b0c4de',
    lightyellow: '#ffffe0',
    lime: '#00ff00',
    limegreen: '#32cd32',
    linen: '#faf0e6',
    magenta: '#ff00ff',
    maroon: '#800000',
    mediumaquamarine: '#66cdaa',
    mediumblue: '#0000cd',
    mediumorchid: '#ba55d3',
    mediumpurple: '#9370d8',
    mediumseagreen: '#3cb371',
    mediumslateblue: '#7b68ee',
    mediumspringgreen: '#00fa9a',
    mediumturquoise: '#48d1cc',
    mediumvioletred: '#c71585',
    midnightblue: '#191970',
    mintcream: '#f5fffa',
    mistyrose: '#ffe4e1',
    moccasin: '#ffe4b5',
    navajowhite: '#ffdead',
    navy: '#000080',
    oldlace: '#fdf5e6',
    olive: '#808000',
    olivedrab: '#6b8e23',
    orange: '#ffa500',
    orangered: '#ff4500',
    orchid: '#da70d6',
    palegoldenrod: '#eee8aa',
    palegreen: '#98fb98',
    paleturquoise: '#afeeee',
    palevioletred: '#d87093',
    papayawhip: '#ffefd5',
    peachpuff: '#ffdab9',
    peru: '#cd853f',
    pink: '#ffc0cb',
    plum: '#dda0dd',
    powderblue: '#b0e0e6',
    purple: '#800080',
    red: '#ff0000',
    rosybrown: '#bc8f8f',
    royalblue: '#4169e1',
    saddlebrown: '#8b4513',
    salmon: '#fa8072',
    sandybrown: '#f4a460',
    seagreen: '#2e8b57',
    seashell: '#fff5ee',
    sienna: '#a0522d',
    silver: '#c0c0c0',
    skyblue: '#87ceeb',
    slateblue: '#6a5acd',
    slategray: '#708090',
    snow: '#fffafa',
    springgreen: '#00ff7f',
    steelblue: '#4682b4',
    tan: '#d2b48c',
    teal: '#008080',
    thistle: '#d8bfd8',
    tomato: '#ff6347',
    turquoise: '#40e0d0',
    violet: '#ee82ee',
    wheat: '#f5deb3',
    white: '#ffffff',
    whitesmoke: '#f5f5f5',
    yellow: '#ffff00',
    yellowgreen: '#9acd32',
  }[name.toLowerCase()];
}
utilityObj.nameToHex = nameToHex;
// TODO potentially move to chat??? only used in chat.js at the moment

/**
 * Find all elements that have link data, and when there is a valid image,
 * replace the loading icon with the actual image.
 * @param {String} url - link urk
 */
function injectLink(url) {
  if (clientUtilities.waitingElesLookup[url] instanceof Array) {
    clientUtilities.waitingElesLookup[url].forEach(handle => {
      handle();
    });
    delete clientUtilities.waitingElesLookup[url];
  }

  // TODO this seems overly complex and spaghetti like, need to refactor/simplify
  const lightDomElements = Array.from(document.querySelectorAll(`[data-links="${url}"]`));
  // TODO shouldn't be query'ing shadowdom like this, please update it set
  // some JSON and let the component handle these shenanigans
  // ^^^^^ goes away with below:
  // TODO find every instance of image inject and migrate to registering
  // functions for when the resource has been fetched
  // Doing this as part of the condensing checkins task since that is where
  // the code in question also happens to be.
  const shadowDomElements = Array.from(document.querySelector('notification-bar').shadowRoot.querySelectorAll(`[data-links="${url}"]`));

  lightDomElements.concat(shadowDomElements).forEach(linkDomObj => {
    const placeholder = linkDomObj.getElementsByClassName('media-placeholder')[0];
    // if (placeholder !== null && typeof placeholder !== 'undefined' && utilityObj.linkImages[url] !== null) {
    if (placeholder !== null &&
        typeof placeholder !== 'undefined' &&
        utilityObj.linkImages[url] !== null &&
        typeof utilityObj.linkImages[url] === 'object' &&
        typeof utilityObj.linkImages[url].imgb64 === 'string' &&
        ( utilityObj.linkImages[url].imgb64.startsWith('data') || utilityObj.linkImages[url].imgb64.startsWith('http'))) {
      placeholder.parentNode.innerHTML = `<img class="thumbnail" src="${utilityObj.linkImages[url].imgb64}">`;
    // } else if (typeof placeholder !== 'undefined' && placeholder !== null) {
    //   placeholder.parentNode.innerHTML = `<div style="width:4em;height:4em"><i class="far fa-image"></i></div><p>No image available</p>`;
    } else {
      linkDomObj.classList.remove('media-message');
      const placeholder = linkDomObj.getElementsByClassName('media-placeholder')[0];
      if (placeholder !== null && typeof placeholder !== 'undefined') {
        const badAnchor = linkDomObj.getElementsByClassName('media-placeholder')[0].parentNode;
        linkDomObj.removeChild(badAnchor);
      }
    }
  });
}


/**
* @param {string} key
* @return {Promise}
*/
function getCachedImage(key) {
  return new Promise((resolve, reject) => {
    if (typeof utilityObj.uploadedImages[key] === 'undefined') {
      // TODO add loading image -- wait until SPA spoof branch is merged in
      // as it contains fixes to the loading image
      // let loadingEle = getLoadingElement();
      handleUploadFetch(key);
      clientUtilities.registerOnFetchedHandle(key, () => {
        resolve(utilityObj.uploadedImages[key]);
      });
      return false;
    }
    if (utilityObj.uploadedImages[key] === null) {
      reject(new Error('bad image reference'));
    }
    if (typeof utilityObj.uploadedImages[key].imgb64 !== 'string') {
      reject(new Error('bad image reference'));
    }
    if (!utilityObj.uploadedImages[key].imgb64.startsWith('data') && !utilityObj.uploadedImages[key].imgb64.startsWith('http')) {
      reject(new Error('bad image reference'));
    }
    resolve(utilityObj.uploadedImages[key]);
  });
}
utilityObj.getCachedImage = getCachedImage;


/**
* @param {string} url
* @param {function} obtainedHandle
* @return {Promise}
*/
function getCachedUrlImage(url, obtainedHandle) {
  utilityObj.linkImages = {};
  return new Promise((resolve, reject) => {
    if (typeof utilityObj.linkImages[url] === 'undefined') {
      // TODO add loading image -- wait until SPA spoof branch is merged in
      // as it contains fixes to the loading image
      // let loadingEle = getLoadingElement();
      handleImgFetch(url);
      clientUtilities.registerOnFetchedHandle(url, () => {
        resolve(utilityObj.linkImages[url]);
      });
      return false;
    }
    if (utilityObj.linkImages[url] === null) {
      reject(new Error('bad image reference'));
    }
    if (typeof utilityObj.linkImages[url].imgb64 !== 'string') {
      reject(new Error('bad image reference'));
    }
    if (!utilityObj.linkImages[url].imgb64.startsWith('data') && !utilityObj.linkImages[url].imgb64.startsWith('http')) {
      reject(new Error('bad image reference'));
    }
    resolve(utilityObj.linkImages[url]);
  });
}
utilityObj.getCachedUrlImage = getCachedUrlImage;


/**
 * A function to be triggered whenenver an action signals notifications would
 * add value to a specific user -- such as clicking a bell icon for a given topic.
 */
function checkToDisplayNotificationPrompt() {
  if (!('Notification' in window && navigator.serviceWorker)) {
    return;
  }

  // Nothing we can do, if the user has already accepted or denied notifications
  // simply skip the rest of the logic.
  if (Notification.permission == 'granted' || Notification.permission == 'denied') {
    return;
  }

  let notificationDate = localStorage.getItem('notificationDate');
  if (!notificationDate) {
    notificationDate = 0;
  } else {
    notificationDate = parseInt(notificationDate);
  }
  if (notificationDate == -1) {// 'never' === -1
    return;
  } else if (notificationDate > Date.now()) {// future wait dait is after current date ts
    return;
  }


  // Display the UI to let the user toggle notifications
  showPromptDialog({
    heading: TEXT_ENUMS.header_notification,
    message: TEXT_ENUMS.canYouEnableNotifications,
    choices: [{
      wording: 'Yes',
      handle: function() {
        Notification.requestPermission(function(permission) {
          // If the user accepts, let's create a notification
          if (permission === 'granted') {
            shellObj.registerPushManager(true);
            displayNotification(TEXT_ENUMS.notificationsEnabled);
            // var notification = new Notification("Hi there!");
          }
        });
      },
    },
    // {
    //   wording: TEXT_ENUMS.askLater,
    //   handle: function() {
    //     // store something locally to not ask again for a few days ( 7 days )
    //     localStorage.setItem('notificationDate', Date.now()+ 7 * 3600 * 1000 );
    //   },
    // },
    {
      wording: TEXT_ENUMS.askNever,
      handle: function() {
        // store something locally to not ask again
        localStorage.setItem('notificationDate', -1);
        // TODO post device figerprinting, store something
      },
    }],
  });
}
utilityObj.checkToDisplayNotificationPrompt = checkToDisplayNotificationPrompt;

/**
 * utility function for injecting TD themantics into notification API.
 * @param {String} message - referenced as 'title' in official API docs
 * @param {object} options - see Notification API documentation for details
 */
function displayNotification(message, options = {}) {
  if (Notification.permission != 'granted') {
    return;
  }

  // more details on options https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
  if (typeof options.lang === 'undefined') {
    options.lang = 'EN';
  }
  if (typeof options.badge === 'undefined') {
    options.badge = '/img/TDlogo.svg';
  }
  if (typeof options.icon === 'undefined') {
    options.icon = '/img/TDlogo.svg';
  }
  if (typeof options.tag === 'undefined') {
    options.tag = 'generalNotification';
  }


  navigator.serviceWorker.getRegistration().then(function(reg) {
    reg.showNotification(message, options);
  });
}

window.addEventListener('load', function load(event) {
  // TODO move to notification component
  // if (!window.indexedDB) {
  //   console.warn(TEXT_ENUMS.error_indexdb);
  // }
  //
  // const request = window.indexedDB.open('Notifications', 4);
  // request.onerror = event => {
  //   console.warn('Why didn\'t you allow my web app to use IndexedDB?!');
  //   console.warn(event);
  // };
  // request.onsuccess = event => {
  //   idb = event.target.result; // this global var is used in other front-end code
  //   idb.onerror = event => {
  //     // Generic error handler for all errors targeted at this database's
  //     // requests!
  //     console.error(`Database error: ${ event.target.errorCode}`);
  //   };
  // };
  // // This event is only implemented in recent browsers
  // request.onupgradeneeded = event => {
  //   // Save the IDBDatabase interface
  //   idb = event.target.result;
  //
  //   if (event.oldVersion < 1) {
  //     try{
  //       idb.deleteObjectStore('events');
  //     }catch(err){
  //
  //     }
  //   }
  //
  //   // Create an objectStore for this database
  //   const objectStore = idb.createObjectStore('events', {autoIncrement: true});
  //   objectStore.createIndex('by_timestamp', 'ts', {unique: false});
  //   objectStore.createIndex('by_topic', 'room', {unique: false});
  // };

  document.body.prepend(overlayContainer);
  document.body.prepend(dialogContainer);
  // TODO REMOVE FOLLOWING LINE
  document.body.prepend(promptBar);
}, false);

/**
 * Function for deleting all the cookies, and forcing a page redirect.
 * also calls respective FB and Google OAuth logout handle functions
 */
function logOutFunction() {
  let count = 2;
  // TODO the following doesn't look right... perhaps checkCount should be
  // called after each try-catch in a finally block?

  document.querySelectorAll('[data-authenticated]').forEach(ele => {
    ele.removeAttribute('data-authenticated');
  });
  document.body.removeAttribute('data-authenticated', true);
  /**
     * nuke all the cookies once FB and GGL logout attempts completed;
     */
  function checkCount() {
    count--;
    if (count === 0) {
      shellObj.deleteAllTokenCookies();

      // setTimeout(function() {
      //   window.location.href='/login.html';
      // }, 100);
    }
  }
  try {
    // function defined in the fb.js
    logOutOfFB(checkCount);
  } catch (fbLougoutErr) {
    checkCount();
  }
  try {
    // function defined in the ggl.js
    logOutOfGoogle(checkCount);
  } catch (gglLougoutErr) {
    checkCount();
  }
}
utilityObj.logOutFunction = logOutFunction;

window.utilityObj = utilityObj;
