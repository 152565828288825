// <aside id="controls-a-side">
//   <notification-bar data-closed="true"></notification-bar>
// </aside>

// <controls-component full-screen="false"></controls-component>

const template = document.createElement('template');
template.innerHTML = `
    <style>
      @import url("/node_modules/@fortawesome/fontawesome-free/css/all.css");
      #controls-container {
        position:relative;
        /* top:60px; */
        /* bottom:0px; */
        /* right:0px; */
        padding:5px;
        height:100%;
        width:48px;
        /* min-height:48px; */
      }

      #controls-container div {
        height:48px;
        width:48px;
        font-size: 48px;
      }

      .compress, .expand{
        /* height:100%; */
        width:100%;
        /* font-size: 48px; */
        color:black;
      }

      #controls-container div:hover,
      .compress:hover, .expand:hover{
        cursor:pointer;
        color:#f47920;
      }
      #controls-container div{
        pointer-events: auto;
      }
      #full-screen-toggle{
        position:absolute;
        bottom:18px;
      }
      /* #full-screen-toggle .compress{
        display:none;
      }
      #full-screen-toggle .expand{
        display:block;
      }
      #full-screen-toggle.fullscreen .expand{
        display:none;
      }
      #full-screen-toggle.fullscreen .compress{
        display:block;
      } */

      @media all and (display-mode: fullscreen) {
        #full-screen-toggle .compress{
          display:block;
        }
        #full-screen-toggle .expand{
          display:none;
        }
      }

      @media not all and (display-mode: fullscreen) {
        #full-screen-toggle .compress{
          display:none;
        }
        #full-screen-toggle .expand{
          display:block;
        }
      }

      /* @media (min-width: 650px){
        #controls-container {
          top:165px;
        }
      } */
    </style>
      <div id="controls-container">
        <div id="full-screen-toggle">
          <div class="expand">
            <i class="fas fa-expand"></i>
          </div>
          <div class="compress">
            <i class="fas fa-compress"></i>
          </div>
        </div>
      </div>
`;


/**
   * Custom Web Component for the nodes that appear on the search results page
   */
class ControlsElement extends HTMLElement {
  /**
     * Simple constructor for TopicElement
     */
  constructor() {
    super();
    // const template = document.getElementById('controls-template');
    const templateContent = template.content;

    this.attributeListeners = {};

    this.attachShadow({mode: 'open'})
        .appendChild(templateContent.cloneNode(true));
  }

  /**
     * baked in method that defines the attributes that matter to the international
     * workings of this component
     */
  static get observedAttributes() {
    return [
      'id',
      'full-screen',
      'icons',
    ];
  }

  /**
     * Handler for changes to attributes
     * @param {string} name
     * @param {string} oldValue
     * @param {string} newValue
     */
  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'id':
        break;
      case 'icons':
        const icons = newValue.split(',').filter(token => token.length > 0);
        const shadowContainer = this.shadowRoot.querySelector('#controls-container');
        const fullScreenToggle = this.shadowRoot.querySelector('#full-screen-toggle');
        icons.forEach((i, iconIndex) => {
          if (!shadowContainer.querySelector(`[data-id=${i}]`)) {
            const div = document.createElement('div');
            div.setAttribute('data-id', i);
            div.innerHTML = `<i class="fas ${i}"></i>`;
            shadowContainer.insertBefore(div, fullScreenToggle);
            div.onclick = () => {this.attributeListeners[i]();};
          }
        });
        const toRemove = [];
        for (let cIndex = 0; shadowContainer.children.length > cIndex; cIndex++ ) {
          const currentEle = shadowContainer.children[cIndex];
          if (currentEle.id === fullScreenToggle.id) {
            continue;
          }
          if (icons.indexOf(currentEle.dataset.id) === -1) {
            toRemove.push(currentEle);
          }
        }
        while (toRemove.length > 0) {
          toRemove.pop().remove();
        }
        icons.forEach((i, iconIndex) => {
          const iconEle = shadowContainer.querySelector(`[data-id=${i}]`);
          while (shadowContainer.children[iconIndex] !== iconEle) {
            const badEle = shadowContainer.children[iconIndex];
            shadowContainer.removeChild(badEle);
            shadowContainer.insertBefore(badEle, fullScreenToggle);
          }
        });
        break;
      default:
        break;
    }
    if (typeof this.attributes[name] !== 'undefined') {

    }
    this.updateStyle();
  }

  /**
     * called everytime a component is attached to the DOM
     */
  connectedCallback() {
    // it's possible a component is connected, and by the time this method is
    // called, that it's disconected, sp we verify it is connected
    if (this.isConnected) {
      this.shadowRoot.querySelector('#full-screen-toggle').onclick = () => {
        this.toggleFullScreen();
        this.updateStyle();
      };
      document.addEventListener('fullscreenchange', event => {
        this.updateStyle();
      });
      this.updateStyle();
    }
  }

  /**
     * takes care of modifying the dom with new values
     */
  updateStyle() {
    if (document.fullscreenElement !== null) {
      this.shadowRoot.querySelector('#full-screen-toggle').classList.add('fullscreen');
    } else {
      this.shadowRoot.querySelector('#full-screen-toggle').classList.remove('fullscreen');
    }
  }

  /**
     * Full screen API toggle
     */
  toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        this.shadowRoot.querySelector('#full-screen-toggle').classList.remove('fullscreen');
      }
    }
  }

  /**
     * regiter event action
     * @param {string} attribute
     * @param {function} handler
     */
  registerIconListener(attribute, handler) {
    this.attributeListeners[attribute] = handler;
  }
}

customElements.define('controls-component', ControlsElement);

export default ControlsElement;

document.addEventListener('touchstart', handleTouchStart, false);
document.addEventListener('touchmove', handleTouchMove, false);

let xDown = null;
let yDown = null;

/**
   * internal function for gestures
   * @param {object} evt
   * @return {Object}
   */
function getTouches(evt) {
  return evt.touches;
}

/**
   * internal function for gestures
   * @param {object} evt
   */
function handleTouchStart(evt) {
  const firstTouch = getTouches(evt)[0];
  xDown = firstTouch.clientX;
  yDown = firstTouch.clientY;
};

/**
   * internal function for gestures
   * @param {object} evt
   */
function handleTouchMove(evt) {
  if ( ! xDown || ! yDown ) {
    return;
  }

  const xUp = evt.touches[0].clientX;
  const yUp = evt.touches[0].clientY;

  const xDiff = xDown - xUp;
  const yDiff = yDown - yUp;

  if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/* most significant*/
    if ( xDiff > 0 ) {
      /* right swipe */
    } else {
      /* left swipe */
    }
  } else {
    if ( yDiff > 0 ) {
      /* down swipe */
    } else {
      /* up swipe */
    }
  }
  /* reset values */
  xDown = null;
  yDown = null;
};

// Initialize deferredPrompt for use later to show browser install prompt.
let deferredPrompt;

window.addEventListener('beforeinstallprompt', beforeInstallEvent => {
  // Prevent the mini-infobar from appearing on mobile
  beforeInstallEvent.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = beforeInstallEvent;
  // Update UI notify the user they can install the PWA
  // showInstallPromotion();
  // Optionally, send analytics event that PWA install promo was shown.

  const controls = document.querySelector('controls-component');
  let icons = [];
  if (controls !== null && typeof controls.getAttribute('icons') === 'string') {
    icons = controls.getAttribute('icons').split(',');
  }
  if (icons.indexOf('fa-arrow-circle-down') === -1) {
    icons.push('fa-arrow-circle-down');
    controls.setAttribute('icons', icons);
  }
  document.querySelector('controls-component').registerIconListener('fa-arrow-circle-down', async () => {
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const {outcome} = await deferredPrompt.userChoice;
    // Optionally, send analytics event with outcome of user choice
    // We've used the prompt, and can't use it again, throw it away
    deferredPrompt = null;
  });
});

window.addEventListener('appinstalled', () => {
  // Hide the app-provided install promotion
  // hideInstallPromotion();
  // Clear the deferredPrompt so it can be garbage collected
  deferredPrompt = null;
  // Optionally, send analytics event to indicate successful install

  const controls = document.querySelector('controls-component');
  let icons = [];
  if (controls !== null && typeof controls.getAttribute('icons') === 'string') {
    icons = controls.getAttribute('icons').split(',');
  }
  const installIconIndex = icons.indexOf('arrow-circle-down');
  if (installIconIndex !== -1) {
    icons.splice(installIconIndex, 1);
    controls.setAttribute('icons', icons);
  }
});
