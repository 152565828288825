import * as moment from 'moment';
import '@fortawesome/fontawesome-free';
// import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
// import "@fortawesome/fontawesome-free/css/all.css";

//
// <aside id="notification-a-side" style="z-index:10">
//   <notification-bar data-closed="true"></notification-bar>
// </aside>

const template = document.createElement('template');
template.innerHTML = `
      <!-- <link href="@fortawesome/fontawesome-free/css/fontawesome.css" rel="stylesheet"> -->
      <style>
        @import url(/@fortawesome/fontawesome-free/css/all.css);
        /* @import url(/node_modules/@fortawesome/fontawesome-free/css/all.css); */
        notification-bar {
          position: fixed;
          right: 5px;
          top: 5vh;
        }

        #sidebar-content.closed{
          display:none;
        }

        .notification-thumbnail{
          height: 48px;
          width: 48px;
          left: 7px;
          position: relative;
          border-radius: 5px;
          top: 3px;
        }

        .notification-number{
          position: absolute;
          border-radius: 100%;
          border: 2px solid darkgray;
          width: 24px;
          height: 24px;
          text-align: center;
          right: 8px;
          top: 8px;
        }

        .notification-moment-ts{
          font-size: 12px;
          opacity: .6;
          position: absolute;
          bottom: 0px;
          right: 6px;
        }
        .latest-notification-moment-ts{
          position: absolute;
          top: 40px;
          font-size: 12px;
          right: 8px;
          opacity: .6;
        }

        #sidebar-notifications .topic-name{
          position: absolute;
          top: 14px;
          left: 65px;
          /* text-align: center; */
          /* width: 100%; */
        }

        .notification-data{
          display: inline-block;
          /* margin: 5px; */
          margin-top: 5px;
          border-radius: 5px;
          background: #eeeeee;
          /* padding: 5px; */
          width: 95%;
          margin-left: 2.5%;
          position: relative;
        }

        .notification-data div{
          display:inline-block;
        }

        .notification-data .event{
          margin:3px;
        }

        .collapsed .notification-data{
          display:none;
        }


        .side-bar-root-option{
          /* transform: rotate(
      90deg); */
          display: inline-block;
          width: 92px;
          height: 42px;
          position: relative;
          /* top: 80px; */
          /* right: 80px; */
          font-size: 20px;
          margin-top: 4px;
          padding-top: 4px;
          line-height: 20px;
          text-align: center;
          border-radius: 5px;
          border:3px solid lightgray;
          border-bottom: 3px solid #f47920;
          color:darkgray;
          background-color:white;
        }
        .side-bar-root-option.selected{
          position:relative;
          /* top: 90px; */
          /* right: 75px; */
          /* border-bottom-left-radius: 5px; */
          /* border-bottom-right-radius: 5px; */
          /* height:30px; */
          color:black;
          border:3px solid #f47920;
          background-color: #f47920;
          /* border: 3px solid #f47920 !important; */
        }
        .side-bar-root-option.selected:hover{
          cursor:initial;
          color:black;
          border:3px solid #f47920;
          background-color: #f47920;
        }
        .side-bar-root-option:hover{
          cursor: pointer;
          color: #38373596;
          border: 3px solid #f479206e;
          background-color: #f4792026;
          /* cursor:pointer;
          color:white;
          border:3px solid #f47920;
          background-color: #f47920; */
        }

        .side-bar-root-option.closed:hover{
          cursor:pointer;
          color:#f47920;
          border:3px solid #f47920 !important;
        }

        .side-bar-root-option.disabled{
          display:none;
        }

        /* .hidden{
          display:none;
        } */

        #scope-content div div, #scope-content div h1{
          display:inline-block;
        }

        #scope-content > div, #sidebar-notifications > div{
          background:lightgray;
          height: 48px;
          margin: 10px;
          border-radius: 10px;
          text-transform: capitalize;
          font-size: 20px;
          position: relative;
          padding-bottom: 5px;
        }

        #sidebar-notifications > div{
          min-height:48px;
          height: inherit;
        }

        #scope-content > div:hover{
          cursor:pointer;
        }

        #scope-content div h1{
          color:#f47920;
          margin-top:0px;
          margin-left:3px;
          margin-right:3px;
          padding-top: 3px;
        }

        #scope-content{
          position:relative;
        }

        #sidebar-options-container{
          height: 54px;
          width: 360px;
          background: #eee;
          color: black;
          opacity: .9;
          border-bottom: 3px solid #f47920;
          border-left: 3px solid #f47920;
          position: relative;
          right: 350px;
          padding-left: 5px;
          top: 0px;
          border-top: 0px;
          z-index: 1;
        }


        #sidebar-content{
          padding-top:58px;
          width: 280px;
          background: white;
          opacity: .9;
          border: 3px solid #f47920;
          padding-left: 15px;
          padding-bottom: 10px;
          padding-right: 15px;
          position: relative;
          right: 360px;

          position: absolute;
          right: 35px;
          min-height: 100vh;
          top: 0px;
          border-top: 0px;
          overflow-y: auto;
          height: 100vh;
        }

        #scope-container{
          margin-top: 6px;
          line-height:48px;
          background:lightgray;
          border-radius:10px;
        }

        #scope-container div{
          display:inline-block;
          width:30%;
          text-align: center;
          height: 30px;
          position: relative;
          line-height: 30px;
          border-radius: 5px;
        }

        #scope-container .selected{
          color:#f47920;
          background-color: #eeeeee;
        }

        #scope-container .disabled{
          color:darkgray;
        }

        .icon-container{
          width: 20px;
          display: inline-block;
        }

        #sidebar-options-container.closed{
          display:none;
        }

        #sidebar-rankings, #sidebar-notifications, #sidebar-custom-viewport{
          display:none;
        }

        #sidebar-rankings.selected, #sidebar-notifications.selected, #sidebar-custom-viewport.selected{
          display:block;
          margin-bottom: 68px;
        }

        #invisablePane{
          top: 0px;
          left: 0px;
          width: 100vw;
          height: 100vh;
          position: fixed;
          display:block;
        }

        #invisablePane.hidden{
          display:none;
        }

        .trending-topic{
          position: absolute;
          height: 100%;
          min-width: 87%;
          line-height: 100%;
        }

        .trending-topic .text{
          position:relative;
          top:16px;
          left: 3px;
        }

        .trending-moment-ts{
          position: absolute;
          bottom: -2px;
          font-size: 12px;
          right: -1px;
          opacity: .6;
        }

        .trending-last-fetch-moment-ts{
          position: absolute;
          right: 0px;
          top: 0px;
        }

        .text img{
          position:relative;
          height:100%;
          width:100%;
        }

        @media (min-width: 650px){
          notification-bar {
            top: 10vh;
          }
        }

        @media screen and (min-width: 1060px){
          #sidebar-content.closed{
            display:block;
          }

          #sidebar-options-container.closed{
            display:block;
          }
        }
      </style>
      <div id="invisablePane" class="hidden"></div>
      <div id="rankings" style="display:none;" class="selected side-bar-root-option"><i class="fas fa-chart-line"></i>Rankings</div>
      <!-- <div id="notifications" class="side-bar-root-option">Notifications</div>
      <div id="spectators" class="hidden side-bar-root-option">Spectators</div> -->
      <div id="sidebar-options-container">
        <div id="trending-option" class="selected side-bar-root-option">
          <div class="icon-container"><i class="fas fa-chart-line"></i></div><br>Trending
        </div>
        <div id="notifications-option" class="side-bar-root-option">
          <div class="icon-container"><i class="far fa-comment-alt"></i></div><br>Notifications
        </div>
        <div id="custom-sidebar-option" class="side-bar-root-option disabled">
          <div class="icon-container" id="custom-tab-icon"><i class="fas fa-history"></i></div>
          <div id="custom-tab-title">Custom</div>
        </div>
      </div>
      <svg style="width:0px;height:0px;">
        <defs>
          <pattern id='image' width="1" height="1" viewBox="0 0 100 100" preserveAspectRatio="none">
            <image xlink:href='/img/merge2-gradiant100x100.png' width="100" height="100" preserveAspectRatio="none"></image>
          </pattern>
        </defs>
      </svg>
      <div id="sidebar-content">
        <div id="sidebar-rankings" class="selected">
          <div id="scope-container">
            <div id="personal-tab" class="disabled">Personal</div>
            <div id="global-tab" class="disabled">Global</div>
            <div id="trending-tab" class="selected">Trending</div>
          </div>
          <div id="scope-content">
            <div onclick=utilityObj.joinConversation("talkdust","chat-server0","LOCALHOST")><h1>1</h1><div class="topic"></div><em class="talking"><div class="media-placeholder" style="width:1em; height:1em;"><i class="fas fa-circle-notch fa-spin"></i></div></em> talking</div>
            <div onclick=utilityObj.joinConversation("covid","chat-server0","LOCALHOST")><h1>2</h1><div class="topic"></div><em class="talking"><div class="media-placeholder" style="width:1em; height:1em;"><i class="fas fa-circle-notch fa-spin"></i></div></em> talking</div>
            <div onclick=utilityObj.joinConversation("weather","chat-server0","LOCALHOST")><h1>3</h1><div class="topic"></div><em class="talking"><div class="media-placeholder" style="width:1em; height:1em;"><i class="fas fa-circle-notch fa-spin"></i></div></em> talking</div>
            <div onclick=utilityObj.joinConversation("olympics","chat-server0","LOCALHOST")><h1>4</h1><div class="topic"></div><em class="talking"><div class="media-placeholder" style="width:1em; height:1em;"><i class="fas fa-circle-notch fa-spin"></i></div></em> talking</div>
            <div onclick=utilityObj.joinConversation("simone_biles","chat-server0","LOCALHOST")><h1>5</h1><div class="topic"></div><em class="talking"><div class="media-placeholder" style="width:1em; height:1em;"><i class="fas fa-circle-notch fa-spin"></i></div></em> talking</div>
            <div onclick=utilityObj.joinConversation("thrift_flipping","chat-server0","LOCALHOST")><h1>6</h1><div class="topic"></div><em class="talking"><div class="media-placeholder" style="width:1em; height:1em;"><i class="fas fa-circle-notch fa-spin"></i></div></em> talking</div>
            <div onclick=utilityObj.joinConversation("rock_climbing","chat-server0","LOCALHOST")><h1>7</h1><div class="topic"></div><em class="talking"><div class="media-placeholder" style="width:1em; height:1em;"><i class="fas fa-circle-notch fa-spin"></i></div></em> talking</div>
            <div onclick=utilityObj.joinConversation("sushi","chat-server0","LOCALHOST")><h1>8</h1><div class="topic"></div><em class="talking"><div class="media-placeholder" style="width:1em; height:1em;"><i class="fas fa-circle-notch fa-spin"></i></div></em> talking</div>
          </div>
        </div>
        <div id="sidebar-notifications">
          <!-- <p>Coming with PWA Release -- very soon! (unlinked some of the code for a more stable Beta)</p> -->
        </div>
        <div id="sidebar-custom-viewport">

        </div>
      </div>`;

/**
   * Custom Web Component for the nodes that appear on the search results page
   */
class NotificationsElement extends HTMLElement {
  /**
     * Simple constructor for TopicElement
     */
  constructor() {
    super();
    // const template = document.getElementById('notifications');
    const templateContent = template.content;

    this.attachShadow({mode: 'open'})
        .appendChild(templateContent.cloneNode(true));
  }

  /**
     * baked in method that defines the attributes that matter to the international
     * workings of this component
     */
  static get observedAttributes() {
    return [
      'id',
      'data-closed',
      'data-custom-title',
      'data-custom-icon',
      'data-custom-enabled',
    ];
  }

  /**
     * Handler for changes to attributes
     * @param {string} name
     * @param {string} oldValue
     * @param {string} newValue
     */
  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'id':
        break;
      case 'data-closed':
      case 'data-custom-icon':
      case 'data-custom-enabled':
      case 'data-custom-title':
        break;
      default:
        break;
    }
    this.updateStyle();
  }

  /**
     * called everytime a component is attached to the DOM
     */
  connectedCallback() {
    // it's possible a component is connected, and by the time this method is
    // called, that it's disconected, sp we verify it is connected
    if (!this.isConnected) {
      return;
    }
    if (typeof FontAwesome !== 'undefined') {
      FontAwesome.dom.i2svg({
        node: this.shadowRoot,
      });
      FontAwesome.dom.watch({
        autoReplaceSvgRoot: this,
        observeMutationsRoot: this.shadowRoot,
      });
    }

    this.shadowRoot.querySelector('#invisablePane').onclick = () => {
      this.shadowRoot.querySelector('#invisablePane').classList.add('hidden');
      this.setAttribute('data-closed', true);
      this.updateStyle();
    };

    this.previousWidth = window.innerWidth || document.documentElement.clientWidth ||
      document.body.clientWidth;
    window.addEventListener('resize', () => {
      const width = window.innerWidth || document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width < 1060 && this.previousWidth >= 1060) {
        this.setAttribute('data-closed', true);
        this.shadowRoot.querySelector('#invisablePane').classList.remove('hidden');
        let icons = [];
        const controls = document.querySelector('controls-component');
        if (controls !== null && typeof controls.getAttribute('icons') === 'string') {
          icons = controls.getAttribute('icons').split(',');
        }
        if (icons.indexOf('fa-chart-line') === -1) {
          icons.unshift('fa-chart-line');
          controls.setAttribute('icons', icons);
        }
      } else if (width >= 1060 && this.previousWidth < 1060) {
        this.setAttribute('data-closed', true);
        this.shadowRoot.querySelector('#invisablePane').classList.add('hidden');
        let icons = [];
        const controls = document.querySelector('controls-component');
        if (controls !== null && typeof controls.getAttribute('icons') === 'string') {
          icons = controls.getAttribute('icons').split(',');
        }
        if (icons.indexOf('fa-chart-line') !== -1) {
          icons.splice(icons.indexOf('fa-chart-line'), 1);
          controls.setAttribute('icons', icons);
        }
      }
      this.previousWidth = width;
    });
    this.shadowRoot.querySelector('#trending-option').onclick = () => {
      this.shadowRoot.querySelector('#trending-option').classList.add('selected');
      this.shadowRoot.querySelector('#notifications-option').classList.remove('selected');
      this.shadowRoot.querySelector('#custom-sidebar-option').classList.remove('selected');

      this.shadowRoot.querySelector('#sidebar-rankings').classList.add('selected');
      this.shadowRoot.querySelector('#sidebar-notifications').classList.remove('selected');
      this.shadowRoot.querySelector('#sidebar-custom-viewport').classList.remove('selected');
    };
    this.shadowRoot.querySelector('#notifications-option').onclick = () => {
      this.shadowRoot.querySelector('#trending-option').classList.remove('selected');
      this.shadowRoot.querySelector('#notifications-option').classList.add('selected');
      this.shadowRoot.querySelector('#custom-sidebar-option').classList.remove('selected');

      this.shadowRoot.querySelector('#sidebar-rankings').classList.remove('selected');
      this.shadowRoot.querySelector('#sidebar-notifications').classList.add('selected');
      this.shadowRoot.querySelector('#sidebar-custom-viewport').classList.remove('selected');
    };
    this.shadowRoot.querySelector('#custom-sidebar-option').onclick = () => {
      this.shadowRoot.querySelector('#trending-option').classList.remove('selected');
      this.shadowRoot.querySelector('#notifications-option').classList.remove('selected');
      this.shadowRoot.querySelector('#custom-sidebar-option').classList.add('selected');

      this.shadowRoot.querySelector('#sidebar-rankings').classList.remove('selected');
      this.shadowRoot.querySelector('#sidebar-notifications').classList.remove('selected');
      this.shadowRoot.querySelector('#sidebar-custom-viewport').classList.add('selected');

      if (typeof this.customHandler === 'function') {
        this.customHandler();
      }
    };

    // TODO figure out why timeout is required to make component insert into shadowdom
    // presumed it would've been appropriate to inject on load, but first attempts
    // were not successful
    // We're currently waiting for controls element to be present in the DOM, that is
    const addIconHandler = () => {
      const controls = document.querySelector('controls-component');
      controls.registerIconListener('fa-chart-line', () => {
        const width = window.innerWidth || document.documentElement.clientWidth ||
        document.body.clientWidth;
        if (width < 1060) {
          this.shadowRoot.querySelector('#invisablePane').classList.add('hidden');
          // enableInvisashader(() => {
          //   this.setAttribute('data-closed', true);
          //   this.updateStyle();
          // });
          if (this.getAttribute('data-closed') == 'true') {
            this.shadowRoot.querySelector('#invisablePane').classList.remove('hidden');
            this.setAttribute('data-closed', false);
          } else {
            this.shadowRoot.querySelector('#invisablePane').classList.add('hidden');
            this.setAttribute('data-closed', true);
            // hideOverlay();
          }
        } else {
          this.shadowRoot.querySelector('#invisablePane').classList.add('hidden');
          // hideOverlay();
        }
      });
      const width = window.innerWidth || document.documentElement.clientWidth ||
      document.body.clientWidth;
      if (width < 1060) {
        let icons = [];
        if (controls !== null && typeof controls.getAttribute('icons') === 'string') {
          icons = controls.getAttribute('icons').split(',');
        }
        if (icons.indexOf('fa-chart-line') === -1) {
          icons.unshift('fa-chart-line');
          controls.setAttribute('icons', icons);
        }
      }
    };
    setTimeout(addIconHandler, 50);

    this.updateTrending();
    setTimeout(() => {this.updateTrending();}, 5000);
    setInterval(() => {this.updateTrending();}, 30000);
    this.updateStyle();
  }

  /**
     * takes care of modifying the dom with new values
     */
  updateStyle() {
    if (!this.isConnected) {
      return;
    }
    if (this.getAttribute('data-closed') == 'true') {
      this.shadowRoot.querySelector('#sidebar-content').classList.add('closed');
      this.shadowRoot.querySelector('#sidebar-options-container').classList.add('closed');
    } else {
      this.shadowRoot.querySelector('#sidebar-content').classList.remove('closed');
      this.shadowRoot.querySelector('#sidebar-options-container').classList.remove('closed');
    }

    if (this.getAttribute('data-custom-enabled') == 'true') {
      this.shadowRoot.querySelector('#custom-sidebar-option').classList.remove('disabled');
    } else {
      this.shadowRoot.querySelector('#custom-sidebar-option').classList.add('disabled');
    }

    if (typeof this.getAttribute('data-custom-title') === 'string' && this.getAttribute('data-custom-title').length > 0) {
      this.shadowRoot.querySelector('#custom-tab-title').innerText = this.getAttribute('data-custom-title');
    } else {
      this.shadowRoot.querySelector('#custom-tab-title').innerText = 'Custom';
    }

    if (typeof this.getAttribute('data-custom-icon') == 'string') {
      this.shadowRoot.querySelector('#custom-sidebar-option #custom-tab-title').innerHTML = `<i class="${this.getAttribute('data-custom-icon')}"></i>`;
    }
  }

  /**
     * function for polling the trending
     */
  updateTrending() {
    const token = shellObj.getCookie('userToken');
    if (typeof token === 'undefined' || token === null || token === '') {
      // Not Authenticated
      return;
    }
    const that = this;
    const globalRegex = new RegExp('^[a-f0-9]{24}$');
    const xmlhttp2 = new XMLHttpRequest();
    xmlhttp2.open('POST', '/lounge/trending', true);
    xmlhttp2.onreadystatechange=function() {
      if (xmlhttp2.readyState == 4 && xmlhttp2.status === 200) {
        const jsonObj = JSON.parse(xmlhttp2.response);
        const roomObjects = jsonObj.filter(roomObj => {
          if (roomObj.topic === 'meta') {
            return false;
          }
          if (globalRegex.test(roomObj.topic)) {
            return false;
          }
          return true;
        });

        roomObjects.sort(function(roomObj1, roomObj2) {
          const activeCount1 = roomObj1.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue);
          const activeCount2 = roomObj2.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue);
          // const activeCount1 = roomObj1.countActiveUsers();
          // const activeCount2 = roomObj2.countActiveUsers();
          // const subscribedCount1 = roomObj1.countSubscribedUsers();
          // const subscribedCount2 = roomObj2.countSubscribedUsers();

          if (activeCount1 === activeCount2) {
            const tsComp = (parseInt(roomObj2.data.activityts) || 0) - ( parseInt(roomObj1.data.activityts) || 0 );
            if (tsComp !== 0) {
              return tsComp;
            }
            if (roomObj1.topic === 'talkdust') {
              return -1;
            } else if (roomObj2.topic === 'talkdust') {
              return 1;
            } else if (roomObj1.topic === 'general') {
              return -1;
            } else if (roomObj2.topic === 'general') {
              return 1;
            } else if (roomObj1.topic === 'sports') {
              return -1;
            } else if (roomObj2.topic === 'sports') {
              return 1;
            } else if (roomObj1.topic === 'politics') {
              return -1;
            } else if (roomObj2.topic === 'politics') {
              return 1;
            } else if (roomObj1.topic === 'news') {
              return -1;
            } else if (roomObj2.topic === 'news') {
              return 1;
            } else if (roomObj1.topic === 'weather') {
              return -1;
            } else if (roomObj2.topic === 'weather') {
              return 1;
            }
            return 0;
          }
          return activeCount2 - activeCount1;
        });
        const domStr = `<p style="font-size: 12px;">Next Update in: </p>
            <em class="trending-last-fetch-moment-ts to-now" style="font-size: 12px; opacity:.8" data-moment-ts="${Date.now()+30000}">
              Updated moments ago
            </em>`;
        that.shadowRoot.querySelector('#scope-content').innerHTML = domStr;
        roomObjects.slice(0, 8).forEach((rObj, index) => {
          const imgArrStr = rObj.data.imageIds;
          const imagesDom = '';
          const attachment = '';
          // // console.log(imgArrStr);
          // console.log(rObj.topic, imgArrStr);
          // if(imgArrStr instanceof Array && imgArrStr.length > 0){
          //   // let images = imgArrStr.split(',');
          //   imagesDom = `<div class="media-placeholder" style="width:1em; height:1em;"></div>`;
          //   attachment = imgArrStr[0];
          //   setTimeout(()=>{handleUploadFetch(attachment)},5);
          // }
          const divFrag = document.createElement('div');
          divFrag.onclick = () => {
            joinConversationFromTrendyBar(rObj.topic, 'chat-server0', 'LOCALHOST');
          };
          divFrag.innerHTML+=`
              <h1>${(index + 1)}</h1>
              <div class="trending-topic">
                <div class="text">
                  <!-- <div data-attachment="${attachment}" style="width:1em; height: 1em; position:relative;">
                    ${imagesDom}
                  </div> -->
                  <div class="topic">${rObj.data.connectionInfo.title}</div>
                  <em class="talking">${rObj.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue)} </em>
                  talking
                </div>
                <em class="trending-moment-ts" data-moment-ts="${parseInt(rObj.data.activityts || '0')}">${moment(parseInt(rObj.data.activityts || '0')).fromNow()}</em>
              </div>`;
          that.shadowRoot.querySelector('#scope-content').appendChild(divFrag);
        });
      } else if (xmlhttp2.readyState === 4) {

      }
    };
    xmlhttp2.send(`search=talkdust&json=true` );
  }

  /**
     * make custom tab display
     */
  enableCustomTab() {
    this.setAttribute('data-custom-enabled', true);
  }

  /**
     * inject stylesheet (URL) to be accessed in shadow dom
     * TODO - seems contradictory to shadow dom... perhaps the js and html
     * for messages needs to be it's own web component and included in here
     * and in the chat.js file.
     * @param {string} styleURL - reference to *.css file
     */
  injectStyleSheet(styleURL) {
    const style = document.createElement('style');
    style.type = 'text/css';
    // style.styleSheet.cssText =`@import url("${styleURL}");`
    style.appendChild(document.createTextNode(`@import url("${styleURL}");`));
    this.shadowRoot.appendChild(style);
  }

  /**
     * set text for custom tab
     * @param {string} title - text for display
     */
  setCustomTitle(title) {
    this.setAttribute('data-custom-title', title);
  }

  /**
     * set font awesome icon for custom tab
     * @param {Object} icon - Font Awesome icon HTML
     */
  setCustomIcon(icon) {
    this.setAttribute('data-custom-icon', icon);
  }

  /**
     * set a function to be called when the custom tab is clicked
     * @param {function} handler
     */
  setCustomTabClickHandler(handler) {
    this.customHandler = handler;
  }

  // TODO - replace this with a method that passes in js objects
  /**
     * returns shadowdom container for the custom tab
     * @return {HTMLElement}
     */
  getCustomTabContainerElement() {
    return this.shadowRoot.querySelector('#sidebar-custom-viewport');
  }

  // TODO - replace this with a method that passes in js objects
  /**
     * returns shadowdom container for the notifications tab
     * @return {HTMLElement}
     */
  getNotificationsTabContainerElement() {
    return this.shadowRoot.querySelector('#sidebar-notifications');
  }

  // TODO evaluate if this is used when picking notifications back up...
  // looks strange that it is empty... uncertain if it's being called though
  // /**
  //   * Render notification object
  //   * @param {Object} msgObj - jsObj to be rendered
  //   */
  // addNotification(msgObj) {
  //
  // }
}

export default NotificationsElement;

/**
Custom ele that contains the actual notification data
*/
class NotificationElement extends HTMLElement {
  /**
     * Simple constructor for TopicElement
     */
  constructor() {
    super();
    // const template = document.getElementById('notifications');
    const templateContent = template.content;

    this.attachShadow({mode: 'open'})
        .appendChild(templateContent.cloneNode(true));
  }

  /**
     * baked in method that defines the attributes that matter to the international
     * workings of this component
     */
  static get observedAttributes() {
    return [
      'id',
      'data-closed',
      'data-custom-title',
      'data-custom-icon',
      'data-custom-enabled',
    ];
  }

  /**
     * Handler for changes to attributes
     * @param {string} name
     * @param {string} oldValue
     * @param {string} newValue
     */
  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'id':
        break;
      case 'data-closed':
      case 'data-custom-icon':
      case 'data-custom-enabled':
      case 'data-custom-title':
        break;
      default:
        break;
    }
    this.updateStyle();
  }

  /**
     * called everytime a component is attached to the DOM
     */
  connectedCallback() {
    // it's possible a component is connected, and by the time this method is
    // called, that it's disconected, sp we verify it is connected
    if (!this.isConnected) {
      return;
    }

    this.updateStyle();
  }

  /**
     * takes care of modifying the dom with new values
     */
  // updateStyle() {
  //   if (!this.isConnected) {
  //     return;
  //   }
  // }
}

/**
   * method for routing a user to join a room
   * @param {string} topic - topic key to join
   * @param {string} chatServer - still to be implemented and used in routing to correct container
   * @param {string} dataCenter - eventually to be used in additional routing, albeit this may be unecessary
   * @param {string} subRoom - group, backend will be into a non full group
   */
function joinConversationFromTrendyBar(topic, chatServer, dataCenter) {
  // alert(`${topic},${chatServer},${dataCenter}`);
  postFromTrendy(`/chat/rooms/${topic}`, {
    action: 'join',
    'chat-server': chatServer,
    'data-center': dataCenter,
  }, 'post');
}
window.joinConversationFromTrendyBar = joinConversationFromTrendyBar;
/**
   * Full credit to Rakesh Pai https://stackoverflow.com/a/133997/1992129
   * I was going to do this, but wanted to google it to ensure it was possible
   * and his code was essentially copy paste!
   * @param {string} path - endpoint
   * @param {Array} params - key:value pairings
   * @param {string} method - HTTP method (ie POST, GET, PUT, etc)
   */
function postFromTrendy(path, params, method) {
  // TODO replace this with the fetch API
  // https://stackoverflow.com/a/46642899/1992129
  method = method || 'post'; // Set method to post by default if not specified.

  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

customElements.define('notification-bar', NotificationsElement);
